import React from 'react'
import { Link } from 'gatsby'

// Components
import Layout from '../components/layouts/default'

const NotFoundPage = () => (
  <Layout>
  <div className="container">
    <h1>HIER BIST DU WOHL FALSCH ABGEBOGEN</h1>
    <p>Die Seite, die du suchst, existiert leider nicht (mehr)!</p>
    <p>
      <Link to="/">Hier gehts zur Startseite</Link>
    </p>
  </div>
  </Layout>
)

export default NotFoundPage
